@import './Themes';
@import "cropperjs/dist/cropper.css";
@import "react-confirm-alert/src/react-confirm-alert.css";
@import './components/BackOffice/index.scss';

//::-webkit-scrollbar {
//  height: 12px;
//  width: 12px;
//  border: 1px solid #d5d5d5;
//  background: gray;
//}
//::-webkit-scrollbar-track {
//  background-color: #f1f1f1;
//}
//::-webkit-scrollbar-thumb {
//  background-color: #888;
//  border-radius: 15px
//}
//::-webkit-scrollbar-thumb:hover {
//  background: #555
//}

body {
  font-family: 'Noto Sans Thai', 'Noto Sans KR', 'Noto Sans', sans-serif;
  text-size-adjust: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.required-field {
  color: red !important;
}


.error-text {
  margin-top: 2px;
  font-size: 10px;
  color: red !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.image-cover {
  object-fit: cover !important;
  object-align: center;
}

.btn-close {
  background: none !important;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//.wallet-card {
//  &.white_white {
//    .card-info-wrapper.card-vertical {
//      background-color: white;
//      * {
//        color: black !important;
//      }
//      svg {
//        fill: black!important;
//        path {
//          fill: black!important;
//        }
//      }
//    }
//  }
//
//  &.bluelight_white {
//    .card-info-wrapper.card-vertical {
//      * {
//        color: black !important;
//      }
//      svg {
//        fill: black!important;
//        path {
//          fill: black!important;
//        }
//      }
//    }
//  }
//}

// custom css for banner alert and count number
.new-request {
  .count-alert {
    color: #FFFFFF;
    margin: auto;
    padding: 0 5px;
    background: red;
    border-radius: 10px;
    font-size: 13px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 11px;
    right: 30px;
  }
  .cancel-banner {
    position: absolute;
    top: 8px;
    right: 12px;
  }
  .btn-new-request {
    width: 180px;
    height: 40px;
    border-radius: 25px;
    background: #3783FC;
    font-size: 16px;
    color: #FFFFFF;
    text-align: left;
  }
}
.count-alert {
  color: #FFFFFF;
  margin: auto;
  padding: 0 7px;
  background: red;
  border-radius: 15px;
  font-size: 16px;
  height: 22px;
  display: flex;
  align-items: center;
}

.rdw-image-alignment {
  display: inline !important;
  z-index: 99999999999;
}

.rdw-image-center {
  display: flex !important;
  justify-content: center;
}

.rdw-image-right {
  float: right !important;
}

.rdw-image-left {
  float: left !important;
}

@media print {
  .position-absolute.chat-icon {
    display: none !important;
  }

  .pagebreak {
    page-break-after: always !important;
    page-break-before: always !important;
  }
  .top-header {
    display: none !important;
  }
}


.pagebreak {
  position: absolute;
}


div.react-calendar {
  .react-calendar__navigation__arrow {
    display: none !important;
  }

  .react-calendar__navigation__label__labelText {
    color: #000;
    text-align: center;
    font-family: Roboto, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      color: #6E7781;
      text-align: center;
      font-family: Roboto, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

  }

  .react-calendar__tile {


    abbr {
      color: #000;
      text-align: center;
      font-family: Roboto, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    abbr {
      opacity: 0.5;
    }
  }

  .react-calendar__tile.react-calendar__tile--now {
    outline: none;
    background: white !important;
    &:disabled {
      background-color: #f0f0f0 !important;
    }
  }
  .react-calendar__tile.react-calendar__tile--active {
    outline: none;
    background: #3B86D7 !important;
    border-radius: 50%;
    abbr {
      color: white !important;
    }
  }
}
